// 컬러 팔레트 
// $main-color: #8471DF;
$main-color: #418DFF;
// $light-color: #C6C8FC;
$light-color: #C6DCFC;
$dark-color: #13193F;
$white-color: #F5F6FE;
$green-color: #C6E1BA;
$gray-color: #E3E0F1;


.dark {
    background-color: $dark-color;
}

.white {
    color: $white-color;
}


@font-face {
    font-family: 'GmarketSans';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff') format('woff'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.ttf') format("truetype");
    font-display: swap;
} 
@font-face {
    font-family: 'GmarketSans';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff') format('woff'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.ttf') format("truetype");
    font-display: swap;
} 
@font-face {
    font-family: 'GmarketSans';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot?#iefix') format('embedded-opentype'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff2') format('woff2'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff') format('woff'),
         url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.ttf') format("truetype");
    font-display: swap;
} 


body {
    background: $white-color;
    font-family: 'GmarketSans', Times, Arial, Helvetica, sans-serif;
    color: $dark-color
}

button {
    font-family: 'GmarketSans', Times, Arial, Helvetica, sans-serif;
}


// 시작 페이지
.start-title {
    padding-top: 150px;
    margin-bottom: 50px;
    // filter: drop-shadow(0px 4px 15px rgba(132, 113, 223, 0.17));
}

#start-title-sub-a {
    font-weight: 100;
    font-size: 15px; 
}


#logo {
    width: 360px;
    padding: 10px;
}

#start-img {
    width: 200px;
}



#start-btn {
    background: $main-color;
    border-radius: 5px;
    width: 160px;
    height: 50px;
    border: 0px;
    color: $white-color;
    font-size: 17px;
    margin-top: 36px;
}

// 테스트 페이지

#logo-nav {
    width: 183px;
    margin-top: 14px;
}

.btn-test {
    background-color: $main-color;
    margin: 5px;
    border-radius: 8px;
    border: 0px;
    padding: 10px;
    color: $white-color;

}

.question {
    padding: 160px 30px 30px 30px;
    height: 200px;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
}

.progress-bar {
    background-color: $main-color!important;
}

.text-center {
    text-align: center;
}

.text-bold {
    font-weight: 500;
}

// 결과 페이지
.result-img {
    width: 250px;
    height: 250px;
    filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1));
    border-radius: 9px;
}

.result {
    margin: auto;
    text-align: center;
}


#result-level-title {
    font-weight: 100;
    font-size: 20px;
}

#result-col {
    padding: 0px!important;
}

.result-level-img {
    width: 70px;
    padding-bottom: 15px;
    
}

#result-level-all {
    font-weight: 100;
    color: $main-color;
    font-size: 10px;
}


.result-level {
    padding-top: 20px;
    position: relative;
}
.result-comments {
    text-align: left;
    padding: 15px;
    font-size: 15px;
    font-weight: 100;
    border: 15px solid $light-color;
}

.share {
    padding: 20px;
}

.share-img {
    width: 40px;
    height: 40px;
}


.answer {
    text-align: center;
    padding-top: 100px;
}


.nav {
    text-align: center;
    height: 60px;
    vertical-align: middle;
    margin: auto;
    font-weight: 600;
    font-size: 18px;
    justify-content: center;
}



.lv-detail {
    padding-bottom: 10px;
}

.lv-detail-title {
    font-weight: 100;
    padding-right: 15px;
}

.lv-detail-row {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    align-items: center;
}

.lv-rect-pink {
    width: 21px;
    height: 10px;
    margin: 1px;
    border-radius: 4px;
    background-color: $green-color;
}

.lv-rect-gray {
    width: 21px;
    height: 10px;
    margin: 1px;
    border-radius: 4px;
    background-color: $gray-color;
}


a, a:hover, a:visited, a:active, a:link {
    color: inherit;
    text-decoration: none;
    text-decoration-line: none!important;
    font-size: 20px;
   }

.share-btn {
    display: inline;
    margin: 3px;
    border: 0mm;
    width: 50px;
    height: 50px;
}

.share-btn-circle {
    border-radius: 40px!important;
    width: 55px;
    height: 55px;
    border: 0;
}

.text-right {
    text-align: right;
}

#restart-btn {
    background: $main-color;
    border-radius: 5px;
    width: 160px;
    height: 50px;
    border: 0px;
    color: $white-color;
    font-size: 17px;
    margin-bottom: 13px;
}